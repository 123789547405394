body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.active-filter button {
  background-color: #a7cc48;
  color: #fff;
}

.active-filter button:hover {
  background-color: #a7cc48;
}

.bg-pry {
  background-color: #a7cc48;

}

.row-statistic {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.statistic-item {
  background-color: #fff;
  /* width: 300px; */
  /* max-width: 90vw; */
  padding: 15px;
  border-radius: 6px;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.2);
}

.table-wrapper {
  max-width: 90vw;
}

.profileType {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 1em 0em;
  width: 50%;
}

.profileType>div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0em 1em;
}

button {
  border: none;
  padding: .5em 1em;
}

.active {
  background-color: #a7cc48;
  color: rgb(255, 255, 255);
}

.unactive {
  background-color: #4273d4;
  /* color: #a7cc48; */
  color: rgb(255, 255, 255);
}

.icon-div {
  display: flex;
  justify-content: flex-end;
  padding: 1em 0em;
  padding-right: 1em;
}

.arrow-div {
  width: 2.3em;
  height: 2.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .7s;
  border-radius: 2em;
  margin: 0em .3em;
}

.arrow-div:hover {
  background-color: #a7cc48;
}

.arrow-image {
  width: 1.3em;
  height: 1.3em;
}

@media screen and (min-width:800px) {
  .row-statistic {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media screen and (min-width:990px) {
  .table-wrapper {
    max-width: 80vw;
  }
}
@media screen and (min-width:1370px) {
  .row-statistic {
    grid-template-columns: repeat(4, 1fr);
  }
}
